<template>
  <!-- Header w/ Nav -->
  <div>
    <Top />
    <Menu />
    <div class="row no-gutters align-items-center">
      <div class="col">
        <BreadCrumb />
      </div>
      <div class="col-12 col-md-auto" v-if="$route.path == '/'">
        <ExchangeRate />
      </div>
    </div>
  </div>
  <!-- ./Header w/ Nav -->
</template>

<script>
const Top = () => import("./components/Top/Index");
const Menu = () => import("./components/Menu/Index");
const BreadCrumb = () => import("./components/BreadCrumb/Index");
const ExchangeRate = () => import("./components/WeExchangeRate/Index");

export default {
  name: "WeAppHeader",
  components: {
    Top,
    Menu,
    BreadCrumb,
    ExchangeRate,
  },
};
</script>

<style lang="scss">
/* ----- NAVBAR ----- */

.nav-top {
  border-radius: 0.2rem;
  min-width: 40px;
  text-align: center;
}

.nav-top:hover {
  background-color: rgb(64, 64, 168);
}

.nav-top:hover .nav-link {
  color: #ffffff !important;
}

.nav-second {
  border-bottom: 3px solid transparent;
}

.nav-active .nav-link {
  font-weight: 600;
  color: #2f3542 !important;
}

.nav-active,
.nav-second:hover {
  border-bottom: 3px solid #ff4757;
  color: #2f3542 !important;
}

.nav-second:hover {
  background-color: #e6eaef !important;
}

.nav-text,
.nav-second span {
  font-size: 0.8rem !important;
}
/* ----- NAVBAR ----- */
@media screen and (max-width: 1366px) {
  #second-navigation {
    .nav-link {
      padding: 1rem 0.4rem !important;
    }
  }
}
@media screen and (max-width: 1000px) {
  #second-navigation {
    .nav-link {
      padding: 0.6rem 1rem !important;
    }
  }
}
@media screen and (min-width: 1367px) {
  #second-navigation {
    .nav-link {
      font-size: 88%;
    }
  }
}
</style>
